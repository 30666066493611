<template>
  <section>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("upsearch_documents.title") }}
                <span class="d-block text-muted pt-2 font-size-sm">{{ $t("upsearch_documents.subtitle") }}</span>
              </h3>
            </div>

            <div class="card-toolbar">
              <!--begin::Button-->

              <v-flex class="mr-2">
                <collection_select />
              </v-flex>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    :headers="headers"
                    :items="documents"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          placeholder="Search in documents"
                          label="Search"
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>
                    <template v-slot:item.name="{ item }">
                      <router-link
                          :to="{
                          name: 'trending-item',
                          query: { id: item.id }
                        }"
                      >
                        {{ item.name }}
                      </router-link>
                    </template>
                    <template v-slot:item.elastic_link="{ item }">
                      <a :href="item.elastic_link" target="_blank">{{ item.elastic_link }}</a>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import collection_select from "@/view/pages/collections/collection_select";
import {LOAD_DATA} from "@/core/services/store/upsearch_doc.module";
import {RELOAD_MESSAGE} from "@/core/services/store/message.module";

export default {
  components: { collection_select },

  data() {
    return {
      search: "",
      headers: [
        { text: "Elastic #ID", value: "id" },
        { text: "Active", value: "sync" },
        { text: "External #ID", value: "sync_id" },
        { text: "Name", value: "name" },
        { text: "Message", value: "message", sortable: false },
        { text: "Last update time", value: "update_time" },
        { text: "Elastic request", value: "elastic_link", sortable: false }
      ],
      options: {}
    };
  },
  methods: {
    reloadData() {
      if (this.selectedCollection && this.selectedCollection.id > 0) {
        this.$store.dispatch(LOAD_DATA, this.selectedCollection);
      }
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    changeCollection() {
      return this.selectedCollection.id;
    },
    ...mapState({
      errors: state => state.upsearch_doc.errors,
      message: state => state.upsearch_doc.message,
      hasMessage: state => state.upsearch_doc.hasMessage,
      documents: state => state.upsearch_doc.documents,
      loading: state => state.upsearch_doc.loading
    }),
    ...mapGetters(["selectedCollection", "currentProject"])
  },
  mounted() {
    this.$store.dispatch(RELOAD_MESSAGE);
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Documents in Upsearch" }]);
  }
};
</script>
